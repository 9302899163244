import resources from "@/common/enums/resources";
import { ADD_ENTITY, DELETE_ENTITY, SET_ENTITY, UPDATE_ENTITY } from "@/store/mutation-types";

const module = "Collections";

const entityCollections = "collections";
const entityCurrentCollection = "currentCollection";

const namespaceCollections = { entity: entityCollections, module };
const namespaceCurrentCollections = { entity: entityCurrentCollection, module };

export default {
    namespaced: true,
    state: {
        collections: [],
        currentCollection: {},
    },
    actions: {
        async fetchCollections({ dispatch }) {
            const collections = await this.$api[resources.COLLECTIONS].query();
            dispatch("setCollections", collections);
        },
        setCollections({ commit }, collections) {
            commit(
                SET_ENTITY,
                {
                    ...namespaceCollections,
                    value: collections,
                },
                { root: true }
            );
        },
        async updateCollection({ commit }, collection) {
            await this.$api[resources.COLLECTIONS].patch(collection);
            commit(
                UPDATE_ENTITY,
                {
                    ...namespaceCollections,
                    value: collection,
                },
                { root: true }
            );
        },
        async deleteCollection({ commit }, id) {
            await this.$api[resources.COLLECTIONS].delete(id);
            commit(
                DELETE_ENTITY,
                {
                    ...namespaceCollections,
                    id,
                },
                { root: true }
            );
        },
        async addCollection({ commit }, collection) {
            const newCollection = await this.$api[resources.COLLECTIONS].post(collection);
            commit(
                ADD_ENTITY,
                {
                    ...namespaceCollections,
                    value: newCollection,
                },
                { root: true }
            );
        },
        async fetchCollection({ dispatch }, collectionId) {
            const collection = await this.$api[resources.COLLECTIONS].get(collectionId);
            dispatch("setCollection", collection);
        },
        setCurrentCollection({ commit }, collection) {
            commit(
                SET_ENTITY,
                {
                    ...namespaceCurrentCollections,
                    value: collection,
                },
                { root: true }
            );
        },
    },
}