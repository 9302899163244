export default [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/Main.vue"),
    meta: { layout: "Dashboard" },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("../views/SignUp.vue"),
    meta: { layout: "Blank" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { layout: "Blank" },
  },
  {
    path: "/collections",
    name: "Collections",
    component: () => import("../views/Collections.vue"),
    meta: { layout: "Dashboard" },
  },
  {
    path: '/collections/:id',
    name: "Collection",
    component: () => import("../views/Collection.vue"),
    meta: { layout: "Dashboard" },
  },
];
