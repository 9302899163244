<template>
  <div>
    <v-app-bar app>
      <v-toolbar-title>Dudes</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>