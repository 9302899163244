import JwtService from "@/services/jwt.service";
import axios from "@/plugins/axios";
import resources from "@/common/enums/resources";

class BaseApiService {
  constructor(notifier) {
    if (!axios.$notifier) {
      axios.$notifier = notifier;
    }
  }
}

export class AuthApiService extends BaseApiService {
  constructor(notifier) {
    super(notifier);
  }
  setAuthHeader() {
    const token = JwtService.getToken();
    axios.defaults.headers.common["Authorization"] = token
      ? `Bearer ${token}`
      : "";
  }

  async login(params) {
    const { data } = await axios.post("login", params);
    return data;
  }

  async signup({ username, email, password}) {
    const { status } = await axios.post("registration", { username, email, password});
    if (status === 200) {
      axios.$notifier.success('Аккаунт зарегистрирован');
    }
    return status === 200;
  }

  async logout() {
    const { data } = await axios.delete("logout");
    return data;
  }
  async getMe() {
    const { data } = await axios.get("whoAmI");
    return data;
  }
}

export class ReadOnlyApiService extends BaseApiService {
  #resource;
  constructor(resource, notifier) {
    super(notifier);
    this.#resource = resource;
  }

  async query(config = {}) {
    const { data } = await axios.get(this.#resource, config);
    return data;
  }

  async get(id, config = {}) {
    const { data } = await axios.get(`${this.#resource}/${id}`, config);
    return data;
  }
}

export class CrudApiService extends ReadOnlyApiService {
  #resource;
  constructor(resource, notifier) {
    super(resource, notifier);
    this.#resource = resource;
  }

  async post(entity) {
    const { data } = await axios.post(this.#resource, entity);
    return data;
  }

  async patch(entity) {
    const { data } = await axios.patch(`${this.#resource}/${entity.id}`, entity);
    return data;
  }

  async delete(id) {
    const { data } = await axios.delete(`${this.#resource}/${id}`);
    return data;
  }
}

export class TaskCrudApiService extends CrudApiService {
  #resource;
  constructor(resource, notifier) {
    super(resource, notifier);
    this.#resource = resource;
  }
  async query (collectionId, config = {}) {
    const { data } = await axios.get(`${resources.COLLECTIONS}/${collectionId}/${this.#resource}`, config);
    return data;
  }
  async post(collectionId, entity) {
    const { data } = await axios.post(`${resources.COLLECTIONS}/${collectionId}/${this.#resource}`, entity);
    return data;
  }
  async upload(taskId, file) {
    const formData = new FormData();
    formData.append('audio', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    const { data, status } = await axios.post(`${this.#resource}/${taskId}/audio`, formData, { headers });
    if (status === 200) {
      axios.$notifier.success('Файл загружен');
    }
    return data;
  }
}
