<template>
  <div>
    <v-navigation-drawer
        permanent
        app
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="avatar"></v-img>
          </v-list-item-avatar>
          <v-list-item-title class="text-h6">
            {{ username }}
          </v-list-item-title>
<!--          <v-list-item-subtitle>email</v-list-item-subtitle>-->
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
          nav
          dense
      >
        <v-list-item link to="/collections">
          <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Коллекции</v-list-item-title>
        </v-list-item>
        <div v-if="isAuthenticated">
<!--          <v-list-item link to="/profile">-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-account-multiple</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title>Профиль</v-list-item-title>-->
<!--          </v-list-item>-->
          <v-list-item @click="$logout">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item link to="/login">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Вход</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/signup">
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Регистрация</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <v-spacer></v-spacer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { logout } from "@/common/mixins";

export default {
  name: "Sidebar",
  mixins: [logout],
  computed: {
    ...mapState("Auth", ["user"]),
    avatar() {
      const id = this.user ? this.user.emailHash : '00000000000000000000000000000000';
      return `https://www.gravatar.com/avatar/${id}`;
    },
    username() {
      return this.userHasUsername? this.user.username : 'Anonymous';
    },
    isAuthenticated: function () {
      return this.user !== null;
    },
    userHasUsername: function () {
      return this.user !== null && this.user.username;
    },
  },
};
</script>

<style scoped></style>