<template>
  <div>
    <v-footer></v-footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>