import resources from "@/common/enums/resources";
import { ADD_ENTITY, DELETE_ENTITY, SET_ENTITY, UPDATE_ENTITY } from "@/store/mutation-types";

const module = "Tasks";

const entityTasks = "tasks";

const namespaceTasks = { entity: entityTasks, module };

export default {
    namespaced: true,
    state: {
        tasks: [],
    },
    actions: {
        async fetchTasks({ dispatch }, collectionId ) {
            const tasks = await this.$api[resources.TASKS].query(collectionId);
            dispatch("setTasks", tasks);
        },
        async updateTask({ commit }, task ) {
            await this.$api[resources.TASKS].patch(task);
            commit(
                UPDATE_ENTITY,
                {
                    ...namespaceTasks,
                    value: task,
                },
                { root: true }
            );
        },
        async deleteTask({ commit }, id ) {
            await this.$api[resources.TASKS].delete(id);
            commit(
                DELETE_ENTITY,
                {
                    ...namespaceTasks,
                    id,
                },
                { root: true }
            );
        },
        async addTask({ commit }, { collectionId, task } ) {
            const newTask = await this.$api[resources.TASKS].post(collectionId, task);
            commit(
                ADD_ENTITY,
                {
                    ...namespaceTasks,
                    value: newTask,
                },
                { root: true }
            );
        },
        async uploadFile({ commit }, { taskId, file } ) {
            const task = await this.$api[resources.TASKS].upload(taskId, file);
            commit(
                UPDATE_ENTITY,
                {
                    ...namespaceTasks,
                    value: task,
                },
                { root: true }
            );
        },

        setTasks({ commit }, tasks) {
            commit(
                SET_ENTITY,
                {
                    ...namespaceTasks,
                    value: tasks,
                },
                { root: true }
            );
        },
    },
}