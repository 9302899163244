import { SET_ENTITY } from "@/store/mutation-types";

const module = "Auth";

const entityUser = "user";

const namespaceUser = { entity: entityUser, module };

export default {
    namespaced: true,
    state: {
        user: null,
    },
    actions: {
        async login({ dispatch }, credentials) {
            const data = await this.$api.auth.login(credentials);
            this.$jwt.saveToken(data.token);
            this.$api.auth.setAuthHeader();
            dispatch("fetchUser");
        },
        async fetchUser({ dispatch }) {
            try {
                const user = await this.$api.auth.getMe();
                dispatch("setUser", user);
            } catch {
                dispatch("logout", false);
            }
        },
        async logout({ dispatch }, sendRequest = false) {
            if (sendRequest) {
                await this.$api.auth.logout();
            }
            this.$jwt.destroyToken();
            this.$api.auth.setAuthHeader();
            dispatch("setUser", null);
        },
        setUser({ commit }, user) {
            commit(
                SET_ENTITY,
                {
                    ...namespaceUser,
                    value: user,
                },
                { root: true }
            );
        },
    },
}