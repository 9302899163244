import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (e) => {
    const defaultMessage = "Возникла ошибка при выполнении запроса к серверу";
    const message = e?.response?.data?.message || defaultMessage;
    const messages = e?.response?.data?.error?.messages || [message]
    messages.forEach(message => axiosInstance.$notifier.error(message));
    return Promise.reject(e);
  }
);

export default axiosInstance;
