import resources from "@/common/enums/resources";
import {
  AuthApiService, CrudApiService, TaskCrudApiService,
} from "@/services/api.service";

export const setAuth = (store) => {
  store.$api.auth.setAuthHeader();
  store.dispatch("Auth/fetchUser");
};
export const createResources = (notifier) => {
  return {
    [resources.AUTH]: new AuthApiService(notifier),
    [resources.COLLECTIONS]: new CrudApiService(resources.COLLECTIONS, notifier),
    [resources.TASKS]: new TaskCrudApiService(resources.TASKS, notifier),
  };
};
